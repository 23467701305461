import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";

@Injectable({
  providedIn: "root"
})
export class LayoutService {

  public sidenavDisplaySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isMobileSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(public breakpointObserver: BreakpointObserver) {
    this.init();
  }

  init() {
    this.breakpointObserver
      .observe(['(max-width: 1300px)'])
      .subscribe((state: BreakpointState) => this.isMobileSubject.next(state.matches));
  }

  toggleSidenav() {
    this.sidenavDisplaySubject.next(!this.sidenavDisplaySubject.value)
  }

}
