import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'safeTranslate'
})
export class SafeTranslatePipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(value: string, untranslatedValue: string): string {
    let translation = this.translate.instant(value);
    if (translation !== value) {
      return translation;
    } else {
      return untranslatedValue.toLowerCase().replace(/_/g,' ');
    }
  }

}
