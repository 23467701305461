import {LatLngExpression, Marker, MarkerOptions} from "leaflet";
import {AnchorPoint} from "@data/model/anchor-point.model";

export class AnchorPointMarker extends Marker {
  anchorPoint: AnchorPoint;

  constructor(latLng: LatLngExpression, anchorPoint: AnchorPoint, options?: MarkerOptions) {
    super(latLng, options);
    this.setAnchorPoint(anchorPoint);
  }

  getAnchorPoint() {
    return this.anchorPoint;
  }

  setAnchorPoint(anchorPoint: any) {
    this.anchorPoint = anchorPoint;
  }
}
