export class OperatingStatus {

  private static readonly metadata : {[index: string]: any} = {
    WEEDING: {color: '#17A81B'},
    CHARGING: {color: '#FB8E31'},
    IDLE: {color: '#818181'},
    WAITING_FOR_MANUAL_TAXI: {color: '#818181'},
    MANUAL_DRIVE: {color: '#2699fb'},
    TAXI_WEEDING: {color: '#818181'},
    TAXI_CHARGING: {color: '#818181'},
    EMERGENCY_STOP_ENGAGED: {color: '#d90000'},
    DEFECT: {color: '#d90000'}
  }

  public static getColor(operatingStatus: string): string | undefined {
    for (const key in OperatingStatus.metadata) {
      if (key === operatingStatus)
        return OperatingStatus.metadata[key].color;
    }

    return undefined;
  }
}

