import {Injectable, NgZone} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {ErrorSnackbarComponent} from "@shared/components/error-snackbar/error-snackbar.component";
import {SuccessSnackbarComponent} from "@shared/components/success-snackbar/success-snackbar.component";
import {WarningSnackbarComponent} from "@shared/components/warning-snackbar/warning-snackbar.component";

@Injectable({providedIn: 'root'})
export class ToastService {

  constructor(private _snackBar: MatSnackBar,
              private _zone: NgZone) {
  }

  success(message: string) {
    this._zone.run(() => {
      this._snackBar.openFromComponent(SuccessSnackbarComponent, {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        data: message
      });
    });
  }

  error(message: string) {
    this._zone.run(() => {
      this._snackBar.openFromComponent(ErrorSnackbarComponent, {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        data: message
      });
    });
  }

  warning(message: string) {
    this._zone.run(() => {
      this._snackBar.openFromComponent(WarningSnackbarComponent, {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        data: message
      });
    });
  }
}
