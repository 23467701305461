export const environment = {
  production: true,
  apiUrl: "https://dev-api.cloud.cyclair.fr",
  sseRoute: "/api/v1/rovers/events",
  iam: {
    url: "https://dev-iam.cloud.cyclair.fr",
    realm: "cyclair",
    clientId: "control-panel"
  },
  defaultLocale: 'fr'
};
