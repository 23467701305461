import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'countTotalNbOfRequiredComponents'
})
export class CountTotalNbOfRequiredComponentsPipe implements PipeTransform {

  transform(value: any, ...args: any[]): number {
    return value.componentTypes.reduce((counter: number, modelComponentType: any) => counter + modelComponentType.nbOfRequiredComponents, 0);
  }

}
