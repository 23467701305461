import {Pipe, PipeTransform} from '@angular/core';

export type DistanceFormatPipeInputs = 'cm' | 'm' | 'km';

@Pipe({
  name: 'distanceFormat',
  pure: false
})
export class DistanceFormatPipe implements PipeTransform {

  transform(value: any, arg1: DistanceFormatPipeInputs): any {

    value = Number(value);
    let valueInMeters: number = 0;
    if (arg1 == 'cm') {
      valueInMeters = value / 100;
    } else if (arg1 == 'm') {
      valueInMeters = value;
    } else if (arg1 == 'km') {
      valueInMeters = value * 1000;
    }

    if (valueInMeters < 100) {
      return `${(valueInMeters * 100).toFixed(2)} cm`;
    } else if (valueInMeters < 1000) {
      return `${(valueInMeters).toFixed(2)} m`;
    } else {
      return `${(valueInMeters / 1000).toFixed(2)} km`;
    }
  }

}
