import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as L from "leaflet";
import {Layer, LeafletMouseEvent} from "leaflet";
import {Cluster} from "@data/model/cluster.model";
import {Parcel} from "@data/model/parcel.model";
import {WaitingZone} from "@data/model/waiting-zone.model";
import {Taxiway} from "@data/model/taxiway.model";
import {ManualTaxiway} from "@data/model/manual-taxiway.model";
import {ChargingStation} from "@data/model/charging-station.model";
import {BehaviorSubject, Observable} from "rxjs";
import {ParcelPolygon} from "@data/model/map/parcel-polygon.model";
import {TaxiwayPolyline} from "@data/model/map/taxiway-polyline.model";
import {ManualTaxiwayPolyline} from "@data/model/map/manual-taxiway-polyline.model";
import {AnchorPointMarker} from "@data/model/map/anchor-point-marker.model";
import {ChargingStationMarker} from "@data/model/map/charging-station-marker.model";
import {WaitingZonePolygon} from "@data/model/map/waiting-zone-polygon.model";
import {MapService} from "@data/service/map.service";
import {GpsPathRecording} from "@data/model/gps-path-recording.model";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnChanges {

  private readonly layersColorConfig = {
    parcel: {
      selectedColor: '#0000FF',
      standardColor: '#FB8826',
    },
    hdz: {
      selectedColor: '#0000FF',
      standardColor: '#fbb826',
    },
    taxiway: {
      selectedColor: '#0000FF',
      standardColor: '#269bfb',
    },
    waitingZone: {
      selectedColor: '#0000FF',
      standardColor: '#2649fb',
    },
  };


  @Input() filters: string[] = [];

  @Output() mapEmitter: EventEmitter<L.Map> = new EventEmitter<L.Map>();

  displayFilters: any = {
    rover: true,
    taxiway: true,
    parcel: true,
    hdz: true,
    chargingStation: true,
    waitingZone: true,
    gpsPathRecording: true
  };

  mapLayers: L.Layer[] = [];
  mapZoom: number = 18;
  mapCenter: L.LatLng;
  mapOptions: L.MapOptions;
  mapBounds: L.LatLngBounds;
  map: L.Map;
  @Input() cluster: Cluster | null;
  @Input() parcel: Parcel | null;
  @Input() gpsPathRecording: GpsPathRecording | null;
  mapMouseMoveEventSubject: BehaviorSubject<LeafletMouseEvent | null> = new BehaviorSubject<LeafletMouseEvent | null>(null);
  $mapMouseMoveEvent: Observable<LeafletMouseEvent | null> = this.mapMouseMoveEventSubject.asObservable();
  @Input() roverMarkers: L.Layer[];
  parcelLayers: ParcelPolygon[] = [];
  hdzLayers: L.Layer[] = [];
  gpsPathRecordingLayers: L.Layer[] = [];
  taxiwayLayers: TaxiwayPolyline[] = [];
  manualTaxiwayLayers: ManualTaxiwayPolyline[] = [];
  anchorPointMarkers: AnchorPointMarker[] = [];
  chargingStationMarkers: ChargingStationMarker[] = [];
  waitingZoneLayers: WaitingZonePolygon[] = [];
  initialMapCenter: L.LatLng = new L.LatLng(46.00, 2.00);// TODO remove init?
  @Input() initialMapCenterLat: number | undefined;
  @Input() initialMapCenterLong: number | undefined;
  @Input() initialMapZoom: number = 18;
  @Input() roverOdometrySequencelayers: Layer[] = [];

  constructor(private readonly _mapService: MapService) {
  }

  ngOnInit(): void {
    this.initMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.cluster && this.cluster) {
      this.displayCluster(this.cluster);
    }
    if(changes.parcel && this.parcel) {
      this.displayParcel(this.parcel);
    }
    if(changes.gpsPathRecording && this.gpsPathRecording) {
      this.displayGpsPath(this.gpsPathRecording);
    }
  }

  private initMap() {
    if(this.initialMapCenterLat && this.initialMapCenterLong) {
      this.initialMapCenter = new L.LatLng(this.initialMapCenterLat, this.initialMapCenterLong);
    }
    this.mapZoom = this.initialMapZoom;
    this.mapOptions = {
      layers: [
        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="http://www.example.com/">Example</a>',
          maxNativeZoom: 19,
          maxZoom: 22,
        }),
      ],
      zoom: this.initialMapZoom,
      zoomControl: false,
      center: this.initialMapCenter,
    };
  }

  onMapReady(map: L.Map) {
    this.map = map;
    this.map.addControl(new L.Control.Zoom({
      position: 'bottomright'
    }))
    // Temporary fix, if possible remove the timeout in the future
    setTimeout(() => {
      map.invalidateSize(true);
    }, 100)

    this.mapEmitter.emit(map);
  }

  onMapMouseMove(event: LeafletMouseEvent) {
    this.mapMouseMoveEventSubject.next(event);
  }

  toggleDisplayFilter(filter: string): void {
    this.displayFilters[filter] = !this.displayFilters[filter];
  }

  private displayCluster(cluster: Cluster) {
    if (cluster.centerCoordinates) {
      this.mapCenter = new L.LatLng(
        cluster.centerCoordinates.latitude,
        cluster.centerCoordinates.longitude,
        cluster.centerCoordinates.altitude
      );
      // this.mapZoom = this.zoomWhenSelected;
    }
    this.parcelLayers = [];
    if (cluster.parcels) {
      cluster.parcels.forEach(parcel => {
        let parcelLayer = this._mapService.buildParcel(parcel, undefined)
        this.parcelLayers.push(parcelLayer);
        if (parcel.hdzs) this.hdzLayers = parcel.hdzs.flatMap(hdz => this._mapService.buildGeofence(hdz.geofence, 'hdz'));
        if (parcel.gpsPathRecordings) this.gpsPathRecordingLayers.push(...parcel.gpsPathRecordings.flatMap(r => this._mapService.buildGpsPathRecording(r)));
      });
    }
    if (cluster.waitingZones) this.waitingZoneLayers = cluster.waitingZones.flatMap(waitingZone => this._mapService.buildWaitingZone(waitingZone, undefined));
    if (cluster.taxiways) this.taxiwayLayers = cluster.taxiways.flatMap(taxiway => this._mapService.buildTaxiway(taxiway, undefined));
    if (cluster.manualTaxiways) this.manualTaxiwayLayers = cluster.manualTaxiways.flatMap(t => this._mapService.buildManualTaxiway(t, undefined));
    if (cluster.anchorPoints) this.anchorPointMarkers = cluster.anchorPoints.flatMap(anchorPoint => this._mapService.buildAnchorPoint(anchorPoint));
    if (cluster.chargingStations) this.chargingStationMarkers = cluster.chargingStations.flatMap(chargingStation => this._mapService.buildChargingStation(chargingStation, undefined));
  }

  private displayParcel(parcel: Parcel) {
    this.parcelLayers = [];
    if(parcel.centerCoordinates) {
      this.mapCenter = new L.LatLng(
        parcel.centerCoordinates.latitude,
        parcel.centerCoordinates.longitude,
        parcel.centerCoordinates.altitude
      );
    }
    let parcelLayer = this._mapService.buildParcel(parcel, undefined)
    this.parcelLayers.push(parcelLayer);
  }

  private displayGpsPath(path: GpsPathRecording) {
    this.gpsPathRecordingLayers = [];
    this.gpsPathRecordingLayers.push(...this._mapService.buildGpsPathRecording(path));
  }





}
