import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, Validators} from "@angular/forms";
import {ParcelService} from "@data/service/parcel.service";
import {Parcel} from "@data/model/parcel.model";
import {LoadingService} from "@shared/services/loading.service";

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  public confirmMessage:string;

  constructor(private readonly dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public text: string) {
  }

  ngOnInit(): void {

  }

  confirm() {
    this.dialogRef.close(true);
  }

  closeDialog(parcel?: Parcel) {
    this.dialogRef.close(false);
  }




}
