import {Component, OnDestroy, OnInit} from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {LayoutService} from "@layout/service/layout.service";
import {Subject, takeUntil} from "rxjs";
import {AuthService} from "@app/service/auth.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  $destroy: Subject<void> = new Subject<void>();
  isMobile: boolean;
  currentUser: any;

  constructor(private readonly keycloakService: KeycloakService,
              private readonly authService: AuthService,
              private readonly layoutService: LayoutService) {
  }

  ngOnInit(): void {
    this.layoutService.isMobileSubject
      .pipe(takeUntil(this.$destroy))
      .subscribe((isMobile) => this.isMobile = isMobile);
    this.authService.getCurrentUser().then(
      user => this.currentUser = user
    )
  }

  logout() {
    this.keycloakService.logout();
  }

  toggleSidenav() {
    this.layoutService.toggleSidenav();
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }


}
