import {LatLngExpression, Polyline, PolylineOptions} from "leaflet";
import {Taxiway} from "@data/model/taxiway.model";

export class TaxiwayPolyline extends Polyline {

  private _taxiway: Taxiway;
  private _selected: boolean = false;

  constructor(taxiway: Taxiway, latlngs: LatLngExpression[] | LatLngExpression[][], options?: PolylineOptions) {
    super(latlngs, options);
    this.taxiway = taxiway;
  }


  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = value;
  }

  set taxiway(value: Taxiway) {
    this._taxiway = value;
  }


  get taxiway(): Taxiway {
    return this._taxiway;
  }
}
