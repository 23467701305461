import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapValues'
})
export class MapValuesPipe implements PipeTransform {
  transform(value: Map<any, any>): any[] {
    return value ? Array.from(value.values()) : [];
  }
}
