import {Subject, takeUntil} from "rxjs";
import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {RoleService} from "@data/service/role.service";

@Directive({
  selector: '[appHasRoles]'
})
export class HasRolesDirective implements OnInit, OnDestroy {
  // the role the user must have
  @Input() appHasRoles: string[];

  @Input() completeMatch: boolean = false;

  stop$ = new Subject<void>();

  isVisible = false;

  /**
   * @param {ViewContainerRef} viewContainerRef
   *  -- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef
   *   -- the templateRef to be potentially rendered
   * @param {RolesService} keycloakService
   *   -- will give us access to the roles a user has
   */
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private roleService: RoleService
  ) {
  }

  ngOnInit() {

    //  We subscribe to the $roles to know the roles the user has
    this.roleService.$roles.pipe(
      takeUntil(this.stop$)
    ).subscribe((roles: any) => {
      // If he doesn't have any roles, we clear the viewContainerRef
      if (!roles) {
        this.viewContainerRef.clear();
      }
      // If the user has the role needed to
      // render this component we can add it
      let authorized = this.completeMatch
        ? this.arrayEquals(roles, this.appHasRoles)
        : roles.some((role: any) => this.appHasRoles.includes(role));

      if (authorized) {
        // If it is already visible (which can happen if
        // his roles changed) we do not need to add it a second time
        if (!this.isVisible) {
          // We update the `isVisible` property and add the
          // templateRef to the view using the
          // 'createEmbeddedView' method of the viewContainerRef
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      } else {
        // If the user does not have the role,
        // we update the `isVisible` property and clear
        // the contents of the viewContainerRef
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    });

  }

  // Clear the subscription on destroy
  ngOnDestroy() {
    this.stop$.next();
  }

  private arrayEquals(a: any, b: any) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }
}
