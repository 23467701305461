<mat-sidenav-container class="container" autosize [class.is-mobile]="isMobile">

  <mat-sidenav #sidenav
               [mode]="isMobile ? 'over' : 'side'"
               [opened]="!isMobile"
               [fixedInViewport]="isMobile"
               fixedTopGap="80">
      <mat-nav-list>
        <mat-list-item [routerLink]="'explore'" [routerLinkActive]="'mat-button-toggle-checked'">
          <span class="nav-button-content">
            <i class="blue-map-icon"></i>
            <span>{{'NAV.EXPLORING.LABEL' | translate}}</span>
          </span>
        </mat-list-item>
        <mat-list-item [routerLink]="'rovers'" [routerLinkActive]="'mat-button-toggle-checked'" [routerLinkActiveOptions]="{exact: true}">
          <span class="nav-button-content">
              <i class="blue-rover-icon"></i>
              <span>{{'NAV.ROVERS.LABEL' | translate}}</span>
          </span>
        </mat-list-item>

        <mat-list-item [routerLink]="'rovers/models'" [routerLinkActive]="'mat-button-toggle-checked'" [routerLinkActiveOptions]="{exact: true}" *appHasRoles="['ADMIN']">
          <span class="nav-button-content">
              <i class="blue-rover-icon"></i>
              <span>{{'NAV.MODELS.LABEL' | translate}}</span>
          </span>
        </mat-list-item>

        <!--        <mat-expansion-panel>-->
        <!--          <mat-expansion-panel-header [routerLink]="'rovers'" [routerLinkActive]="'mat-button-toggle-checked'">-->

        <!--          </mat-expansion-panel-header>-->
        <!--          <mat-nav-list>-->
        <!--            <mat-list-item [routerLink]="'rovers/models'" [routerLinkActive]="'mat-button-toggle-checked'">-->
        <!--              <span class="nav-button-content">-->
        <!--                <i class="blue-rover-icon"></i>-->
        <!--                <span>{{'NAV.MODELS.LABEL' | translate}}</span>-->
        <!--              </span>-->
        <!--            </mat-list-item>-->
        <!--          </mat-nav-list>-->
        <!--        </mat-expansion-panel>-->

        <mat-list-item [routerLink]="'clusters'" [routerLinkActive]="'mat-button-toggle-checked'" *appHasRoles="['ADMIN', 'OPERATOR']">
          <span class="nav-button-content">
          <i class="blue-cluster-icon icon"></i>
          <span>{{'NAV.CLUSTERS_AND_PARCELS.LABEL' | translate}}</span>
          </span>
        </mat-list-item>


        <mat-list-item [routerLink]="'users'" [routerLinkActive]="'mat-button-toggle-checked'" *appHasRoles="['ADMIN']">
          <span class="nav-button-content">
            <i class="blue-group-icon"></i>
            <span>{{'NAV.USERS.LABEL' | translate}}</span>
          </span>
        </mat-list-item>

<!--        <mat-list-item [routerLinkActive]="'mat-button-toggle-checked'" *appHasRoles="['ADMIN']">-->
<!--          <span class="nav-button-content">-->
<!--            <i class="blue-activity-icon"></i>-->
<!--            <span>{{'NAV.ACTIVITY.LABEL' | translate}}</span>-->
<!--          </span>-->
<!--        </mat-list-item>-->

        <mat-list-item [routerLink]="'logs'" [routerLinkActive]="'mat-button-toggle-checked'" *appHasRoles="['ADMIN']">
          <span class="nav-button-content">
            <i class="blue-logs-icon"></i>
            <span>{{'NAV.LOGS.LABEL' | translate}}</span>
          </span>
        </mat-list-item>

<!--        <mat-list-item [routerLinkActive]="'mat-button-toggle-checked'">-->
<!--          <span class="nav-button-content">-->
<!--            <i class="blue-help-icon"></i>-->
<!--            <span>{{'NAV.HELP.LABEL' | translate}}</span>-->
<!--          </span>-->
<!--        </mat-list-item>-->
      </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content class="content">
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
