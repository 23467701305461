export const locale = {
  lang: 'en',
  data: {
    LOCALE: {
      fr: "French",
      en: "English",
      de: "German"
    },
    ERROR: {
      SERVER: {
        CODE: {
          1: "User not found"
        },
        STATUS: {
          0: "Oops an error occurred ! Please try again",
          400: "Oops an error occurred ! Please try again",
          401: "Wrong login",
          403: "You don't have the permission to access this content",
          404: "Content could not be found !",
          500: "Oops an error occurred ! Please try again",
        }
      }
    }
  }
}
