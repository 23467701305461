import {LatLngExpression, Polyline, PolylineOptions} from "leaflet";
import {Taxiway} from "@data/model/taxiway.model";
import {ManualTaxiway} from "@data/model/manual-taxiway.model";

export class ManualTaxiwayPolyline extends Polyline {

  private _taxiway: ManualTaxiway;

  private _selected: boolean = false;

  constructor(taxiway: ManualTaxiway, latlngs: LatLngExpression[] | LatLngExpression[][], options?: PolylineOptions) {
    super(latlngs, options);
    this.taxiway = taxiway;
  }


  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = value;
  }

  set taxiway(value: Taxiway) {
    this._taxiway = value;
  }


  get taxiway(): Taxiway {
    return this._taxiway;
  }
}
