import {Component, ContentChild, Input, OnInit} from '@angular/core';
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {FormGroup} from "@angular/forms";
import {MatInput} from "@angular/material/input";

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent implements OnInit {


  @ContentChild(MatInput) input: any;
  @Input() appearance: MatFormFieldAppearance = "outline";


  hide: boolean = true;

  constructor() {
  }

  ngOnInit(): void {

  }

  toggleHide() {
    this.hide = !this.hide;
    this.input.type = this.hide ? 'password' : 'text';
  }

}
