import {KeycloakService} from "keycloak-angular";
import {environment} from '@env/*';

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.iam.url,
        realm: environment.iam.realm,
        clientId: environment.iam.clientId
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/keycloak/silent-check-sso.html'
      }
    });
}
