import {Injectable} from "@angular/core";
import {KeycloakService} from "keycloak-angular";
import {KeycloakProfile} from "keycloak-js";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AuthService {

  private userSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  user$ = this.userSubject.asObservable();

  constructor(private readonly keycloakService: KeycloakService) {
    this.keycloakService.keycloakEvents$.subscribe((event) => {
      if (event.type === 4 || event.type === 3) {
        this.updateCurrentUser();
      }
    });

    this.updateCurrentUser();
  }

  private async updateCurrentUser() {
    if (await this.keycloakService.isLoggedIn()) {
      const profile = await this.keycloakService.loadUserProfile();
      this.userSubject.next(profile);
    } else {
      this.userSubject.next(null);
    }
  }

  async getCurrentUser(): Promise<KeycloakProfile | null> {
    return this.keycloakService.isLoggedIn().then(isLoggedIn => {
      if(isLoggedIn) return this.keycloakService.loadUserProfile()
      else return null;
    });
  }
}
