import {LatLngExpression, Polygon, PolylineOptions} from "leaflet";
import {Parcel} from "@data/model/parcel.model";

export class ParcelPolygon extends Polygon {

  private _parcel: Parcel;
  private _selected: boolean = false;

  constructor(parcel: Parcel, latlngs: LatLngExpression[] | LatLngExpression[][] | LatLngExpression[][][], options?: PolylineOptions) {
    super(latlngs, options);
    this.parcel = parcel;
  }


  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = value;
  }

  set parcel(value: Parcel) {
    this._parcel = value;
  }


  get parcel(): Parcel {
    return this._parcel;
  }
}
