import {LatLngExpression, Marker, MarkerOptions} from "leaflet";
import {ChargingStation} from "@data/model/charging-station.model";

export class ChargingStationMarker extends Marker {
  chargingStation: ChargingStation;

  constructor(latLng: LatLngExpression, chargingStation: ChargingStation, options?: MarkerOptions) {
    super(latLng, options);
    this.setChargingStation(chargingStation);
  }

  getAnchorPoint() {
    return this.chargingStation;
  }

  setChargingStation(chargingStation: any) {
    this.chargingStation = chargingStation;
  }
}
