import {LatLngExpression, Polygon, PolylineOptions} from "leaflet";
import {WaitingZone} from "@data/model/waiting-zone.model";

export class WaitingZonePolygon extends Polygon {

  private _waitingZone: WaitingZone;
  private _selected: boolean = false;

  constructor(waitingZone: WaitingZone, latlngs: LatLngExpression[] | LatLngExpression[][] | LatLngExpression[][][], options?: PolylineOptions) {
    super(latlngs, options);
    this.waitingZone = waitingZone;
  }


  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = value;
  }

  set waitingZone(value: WaitingZone) {
    this._waitingZone = value;
  }


  get waitingZone(): WaitingZone {
    return this._waitingZone;
  }
}
