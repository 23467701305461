<div class="map-container">
  <div class="map-overlay-header-container">
    <div class="map-overlay-header">
      <div class="header-row">
        <div class="filter-container">
          <mat-chip-list selectable="false">
            <mat-chip *ngIf="this.filters.includes('rover')" (click)="toggleDisplayFilter('rover')">
              <span class="valid-icon"><i class="valid" *ngIf="displayFilters?.rover"></i></span>
              {{'EXPLORE.FILTERS.ROVER' | translate}}
            </mat-chip>
            <mat-chip *ngIf="this.filters.includes('taxiway')"  (click)="toggleDisplayFilter('taxiway')">
              <i class="valid" *ngIf="displayFilters?.taxiway"></i>
              {{'EXPLORE.FILTERS.TAXIWAY' | translate}}
            </mat-chip>
            <mat-chip *ngIf="this.filters.includes('parcel')"  (click)="toggleDisplayFilter('parcel')">
              <i class="valid" *ngIf="displayFilters?.parcel"></i>
              {{'EXPLORE.FILTERS.PARCEL' | translate}}
            </mat-chip>
            <mat-chip *ngIf="this.filters.includes('chargingStation')"  (click)="toggleDisplayFilter('chargingStation')">
              <i class="valid" *ngIf="displayFilters?.chargingStation"></i>
              {{'EXPLORE.FILTERS.CHARGING_STATION' | translate}}
            </mat-chip>
            <mat-chip *ngIf="this.filters.includes('waitingZone')"  (click)="toggleDisplayFilter('waitingZone')">
              <i class="valid" *ngIf="displayFilters?.waitingZone"></i>
              {{'EXPLORE.FILTERS.WAITING_ZONE' | translate}}
            </mat-chip>
            <mat-chip *ngIf="this.filters.includes('hdz')"  (click)="toggleDisplayFilter('hdz')">
              <i class="valid" *ngIf="displayFilters?.hdz"></i>
              {{'EXPLORE.FILTERS.HDZ' | translate}}
            </mat-chip>
            <mat-chip *ngIf="this.filters.includes('gps')"  (click)="toggleDisplayFilter('gpsPathRecording')">
              <i class="valid" *ngIf="displayFilters?.gpsPathRecording"></i>
              {{'EXPLORE.FILTERS.GPS_RECORDING' | translate}}
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>

  <div class="map"
       leaflet
       (leafletMapReady)="onMapReady($event)"
       (leafletMouseMove)="onMapMouseMove($event)"
       [leafletOptions]="mapOptions"
       [leafletFitBounds]="mapBounds"
       [leafletLayers]="mapLayers"
       [(leafletZoom)]="mapZoom"
       [(leafletCenter)]="mapCenter">
    <div [leafletLayers]="parcelLayers" *ngIf="parcelLayers && displayFilters?.parcel"></div>
    <div [leafletLayers]="hdzLayers" *ngIf="hdzLayers && displayFilters?.hdz"></div>
    <div [leafletLayers]="waitingZoneLayers" *ngIf="waitingZoneLayers && displayFilters?.waitingZone"></div>
    <div [leafletLayers]="taxiwayLayers" *ngIf="taxiwayLayers && displayFilters?.taxiway"></div>
    <div [leafletLayers]="manualTaxiwayLayers" *ngIf="manualTaxiwayLayers && displayFilters?.taxiway"></div>
    <div [leafletLayers]="gpsPathRecordingLayers"
         *ngIf="gpsPathRecordingLayers && displayFilters?.gpsPathRecording"></div>
    <div [leafletLayers]="anchorPointMarkers" *ngIf="anchorPointMarkers && displayFilters?.taxiway"></div>
    <div [leafletLayers]="chargingStationMarkers"
         *ngIf="chargingStationMarkers && displayFilters?.chargingStation"></div>
    <div [leafletLayers]="roverMarkers" *ngIf="roverMarkers && displayFilters?.rover"></div>
    <div [leafletLayers]="roverOdometrySequencelayers" *ngIf="roverOdometrySequencelayers" ></div>
  </div>
</div>
