import {LatLngExpression, Marker, MarkerOptions} from "leaflet";
import {Rover} from "@data/model/rover.model";

export class RoverMarker extends Marker {
  rover: Rover;

  constructor(latLng: LatLngExpression, rover: Rover, options?: MarkerOptions) {
    super(latLng, options);
    this.setRover(rover);
  }

  getRover() {
    return this.rover;
  }

  setRover(rover: any) {
    this.rover = rover;
  }
}
