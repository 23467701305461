import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  @Input() mode: string = 'single';
  @Input('control') formControl: AbstractControl;
  @Input() placeholder: string;
  // comma separated list of file types
  @Input() requiredFileType: string;

  @ViewChild('fileInput') fileInput: ElementRef;
  fileName: string = '';

  extensionToContentType: { [key: string]: string } = {
    'gpx': 'application/gpx+xml',
    'json': 'application/json',
    'xml': 'application/xml',
    'txt': 'text/plain',
    'jpg': 'image/jpeg',
    'png': 'image/png',
    'csv': 'text/csv',
    // add more mappings as needed
  };

  constructor() {
  }

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe({
      next: (value) => {
        if (value === null) {
          this.deselectFile();
        }
      }
    })
  }

  onFileSelected(event: any) {
    let file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;
      let contentType = this.parseContentType(this.fileName);
      file = new File([file], this.fileName, {type: contentType});
      this.formControl.setValue(file);
    }

  }

  parseContentType(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    return extension ? this.extensionToContentType[extension] || 'application/octet-stream' : 'application/octet-stream';
  }

  deselectFile() {
    this.fileName = '';
    this.formControl.reset('');
    this.fileInput.nativeElement.value = '';
  }

}
