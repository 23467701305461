<mat-form-field appearance="outline" class="form-field">
  <input matInput
         type="text"
         [(ngModel)]="fileName"
         disabled
         placeholder="{{placeholder}}">
  <mat-icon *ngIf="!fileName"
            matSuffix
            class="icon material-icons-outlined"
            (click)="fileInput.click()">file_upload
  </mat-icon>
  <mat-icon *ngIf="fileName"
            class="icon"
            (click)="deselectFile()"
            matSuffix>close
  </mat-icon>
  <input
    type="file"
    #fileInput
    class="file-input"
    (change)="onFileSelected($event)"
    name="file"
    accept="{{requiredFileType}}"
    multiple="multiple"/>
</mat-form-field>
