<mat-toolbar class="header" [class.is-mobile]="isMobile">
  <div class="logo-container">
    <button mat-icon-button *ngIf="isMobile" (click)="toggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
    <img src="assets/images/logo-min.png" alt="cyclair-logo" class="logo">
  </div>
  <span class="spacer"></span>
  <!--  <i class="notification-without-badge"></i>-->
  <span class="profile-container" [matMenuTriggerFor]="userMenu" [matMenuTriggerData]="{user: currentUser}">
    <img class="profile-image" src="assets/images/user.png" alt="user">
  </span>

  <mat-menu #userMenu [class]="'user-menu'">
    <ng-template matMenuContent let-user="user">
      <div class="user-menu-header">
        <div class="profile-container">
          <img class="profile-image" src="assets/images/user.png" alt="user">
        </div>
        <div>
          {{ user.email }}
        </div>
        <button mat-stroked-button color="warn" (click)="logout()">{{ 'USERS.DISCONNECT' | translate }}</button>
      </div>
    </ng-template>
  </mat-menu>
</mat-toolbar>
