<div class="content-container">
  <div class="header">
    <h4>{{'CONFIRMATION_DIALOG.TITLE' | translate}}</h4>
    <mat-icon class="close" (click)="closeDialog()">close</mat-icon>
  </div>
  <div class="partial-divider-wrapper">
    <mat-divider class="primary-divider"></mat-divider>
  </div>
  <div>
    {{text | translate}}
  </div>
  <mat-divider></mat-divider>
  <div class="footer">
    <button mat-raised-button color="secondary"
            (click)="closeDialog()"
            class="button">{{'CONFIRMATION_DIALOG.CANCEL' | translate}}</button>
    <button mat-raised-button color="primary"
            (click)="confirm()"
            class="button">{{'CONFIRMATION_DIALOG.CONFIRM' | translate}}</button>
  </div>
</div>
