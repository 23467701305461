import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {KeycloakService} from "keycloak-angular";

@Injectable({
  providedIn: "root"
})
export class RoleService {

  private rolesSubject = new BehaviorSubject<string[]>([]);
  $roles: Observable<string[]> = this.rolesSubject.asObservable();

  constructor(private readonly keycloakService: KeycloakService) {
    this.rolesSubject.next(this.keycloakService.getUserRoles());
  }

  isUserInRole(role: string): boolean {
    return this.keycloakService.getUserRoles().includes(role);
  }


}
